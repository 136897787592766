import { Permission, TourName } from '~/typings/enums';

export default {
    name: TourName.Caregroup,
    data(): any {
        return {
            steps: [
                {
                    target: '[data-v-step="caregroup:name"]',
                    params: {
                        placement: 'bottom',
                    },
                    content: `
                        <div class="flex-column mb-4 text-left">
                            <h5 class="font-medium font-condensed mb-2">
                                Uw zorggroep
                            </h5>
                            <p>
                                U bent onderdeel van een zorggroep. Dit is de instelling waar u werkzaam bent.
                            </p>
                        </div>
                    `,
                },
                {
                    target: '[data-v-step="caregroup:account_manager"]',
                    params: {
                        placement: 'bottom',
                    },
                    content: `
                        <div class="flex-column mb-4 text-left">
                            <h5 class="font-medium font-condensed mb-2">
                                Accountmanager
                            </h5>
                            <p>
                                Hier ziet u wie de accountmanager van uw zorggroep is met zijn/haar contactgegevens.
                            </p>
                        </div>
                    `,
                    requiredPermissions: [
                        Permission.Global_UserManagement_Overview,
                        Permission.Global_PatientManagement_Overview,
                    ],
                },
                {
                    target: '[data-v-step="caregroup:add_users"]',
                    params: {
                        placement: 'right',
                    },
                    content: `
                        <div class="flex-column mb-4 text-left">
                            <h5 class="font-medium font-condensed mb-2">
                                Toevoegen collega’s
                            </h5>
                            <p>
                                Door hier te klikken kunt u collega’s toevoegen. Wilt u een
                                collega toevoegen? Neem dan contact op met uw accountmanager.
                                Deze helpt u graag verder!
                            </p>
                        </div>
                    `,
                    requiredPermissions: [
                        Permission.Global_UserManagement_Overview,
                        Permission.Global_PatientManagement_Overview,
                    ],
                },
                {
                    target: '[data-v-step="caregroup:view_user"]',
                    params: {
                        placement: 'right',
                    },
                    content: `
                        <div class="flex-column mb-4 text-left">
                            <h5 class="font-medium font-condensed mb-2">
                                Gebruikers
                            </h5>
                            <p>
                                Voor meer informatie over een gebruiker kunt u klikken op het oogje.
                            </p>
                        </div>
                    `,
                    requiredPermissions: [
                        Permission.Global_UserManagement_Overview,
                        Permission.Global_PatientManagement_Overview,
                    ],
                },
            ],
        };
    },
};
