import useLoading from '~/lib/hooks/loading';
import useHttp from '~/lib/http';
import createModel, { defineModel } from '~/lib/model';
import type { Modules, CaregroupType } from '~/typings/enums';
import type { Model, ModelDefinition } from '~/typings/model';

import type { Uuid } from '~/typings/types';

export interface Caregroup extends Model {
    name: string;
    parent_uuid: Uuid | null;
    type: CaregroupType;
    logo_url: string | null;
    email: string | null;
    website: string | null;
    structure: Array<{
        name: string;
        uuid: Uuid;
    }> | null;
    user_count: number;
    patient_count: number;
    partner_id: number | null;

    // Relationships
    address_uuid: Uuid | null;
    address_street?: string;
    address_zipcode?: string;
    address_city?: string;
    phonenumber_uuids: Uuid[];

    modules: Modules[];
}

export interface CreateCaregroup {
    parent_uuid?: Uuid;
    name: string;
    street_number: string;
    street_number_addition?: string;
    zipcode: string;
}

const caregroupModel = createModel('caregroups', defineModel<Caregroup, Caregroup, CreateCaregroup>(), {
    actions: {
        async uploadLogo(uuid: Uuid, file: File) {
            return useLoading(async () => {
                const formData = new FormData();
                formData.append('logo', file);

                return useHttp().post<string|null>(`/caregroups/logo/${uuid}`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                })
                    .then(url => {
                        if (!url) return;

                        const caregroup = this.models.get(uuid);

                        if (caregroup) {
                            caregroup.logo_url = url;
                        }
                    });
            })();
        },
        async deleteLogo(uuid: Uuid) {
            return useLoading(async () => {
                return useHttp().delete(`/caregroups/logo/${uuid}`)
                    .then(() => {
                        const caregroup = this.models.get(uuid);

                        if (caregroup) {
                            caregroup.logo_url = null;
                        }
                    });
            })();
        },
        async delete(uuid: Uuid) {
            return useLoading(async () => {
                return useHttp().delete(`/caregroups/${uuid}`)
                    .then(() => {
                        this.models.delete(uuid);
                    });
            })();
        },
    },
});

const useCaregroup = (): ModelDefinition<typeof caregroupModel> => caregroupModel();

export default useCaregroup;
