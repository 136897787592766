<script setup lang="ts">
import { getTourName, getRouteTourSteps, routeTourNames } from '~/helpers/tours';
import useAuth from '~/stores/Auth';

import type { TourStep } from '~/typings/types';
import type { TourName } from '~/typings/enums';

type Props = {
    name?: TourName | null;
    tourSteps: TourStep[] | null;
};

interface Events {
    (event: 'started', value: any): void;
}

//  NOTE: Only required if the tour is not route based.
const props = withDefaults(defineProps<Props>(), {
    name: null,
    tourSteps: null,
});

const emit = defineEmits<Events>();

const isTourEnabled = import.meta.env.VITE_IS_TOUR_ENABLED;

const auth = useAuth();
const route = useRoute();
const tours = ref();
const steps = ref<TourStep[]>([]);
const tourName = computed((): TourName | null => props.name ?? getTourName(route.name?.toString()) ?? null);

function startTour(): void {
    if (tourName.value && tours.value) {
        const currentTour = tours.value[tourName.value];

        currentTour.start();

        emit('started', currentTour);
    }
}

watch(() => tourName.value, async (value: TourName | null) => {
    if (!isTourEnabled || !value || auth.isGuest) return;

    const tourSteps = props.tourSteps || await getRouteTourSteps(value);

    steps.value = tourSteps
        .filter((step: TourStep) => !step.shouldSkip?.())
        .filter((step: TourStep) => {
            return !step.requiredPermissions || step.requiredPermissions.length === 0
                ? true
                : auth.hasAnyPermissionTo(step.requiredPermissions);
        });

    if (!auth.hasFinishedTour(value) && value) {
        window.setTimeout(() => {
            auth.finishTour(value);
            auth.updateFinishedTours(value);

            startTour();
        }, 1000);
    }
}, { immediate: true });

onMounted(() => {
    const instance = getCurrentInstance();

    if (instance) {
        tours.value = instance.appContext.config.globalProperties.$tours;
    }
});
</script>

<template>
    <template v-if="isTourEnabled === 'true'">
        <Teleport to="body">
            <v-tour
                v-for="n in (props.name ? [props.name] : routeTourNames)"
                :key="n"
                :name="n"
                :steps="steps"
                :callbacks="[]"
                :options="{ highlight: true }"
            >
                <template #default="tour">
                    <transition name="fade">
                        <v-step
                            v-if="tour.steps[tour.currentStep]"
                            :key="tour.currentStep"
                            ref="tourRef"
                            :step="tour.steps[tour.currentStep]"
                            :previous-step="tour.previousStep"
                            :next-step="tour.nextStep"
                            :stop="tour.stop"
                            :skip="tour.skip"
                            :is-first="tour.isFirst"
                            :is-last="tour.isLast"
                            :labels="tour.labels"
                            :highlight="true"
                            class="!bg-wgm-blue-900 !p-4"
                        >
                            <template #header>
                                <div class="mb-2 flex justify-between">
                                    <div class="mr-2 font-semibold">
                                        {{ tour.currentStep + 1 }} / {{ steps.length }}
                                    </div>
                                    <Button
                                        theme="link"
                                        size="sm"
                                        class="text-white px-0"
                                        @click="tour.stop"
                                    >
                                        <IconXMark
                                            width="24"
                                            height="24"
                                            class="cursor-pointer invert"
                                        />
                                    </Button>
                                </div>
                            </template>
                            <template #actions>
                                <div class="flex justify-end gap-4">
                                    <Button
                                        v-if="!tour.isFirst"
                                        theme="link"
                                        size="sm"
                                        class="text-white px-0"
                                        @click="tour.previousStep"
                                    >
                                        {{ $t('forms.pagination.previous') }}
                                    </Button>
                                    <Button
                                        v-if="tour.isLast"
                                        theme="link"
                                        size="sm"
                                        class="text-white px-0"
                                        @click="tour.stop"
                                    >
                                        {{ $t('forms.actions.close') }}
                                    </Button>
                                    <Button
                                        v-else
                                        theme="link"
                                        size="sm"
                                        class="text-white px-0"
                                        @click="tour.nextStep"
                                    >
                                        {{ $t('forms.pagination.next') }}
                                    </button>
                                </div>
                            </template>
                        </v-step>
                    </transition>
                </template>
            </v-tour>
        </Teleport>
    </template>
</template>

<style lang="css">
#app .v-tour__target--highlighted {
    box-shadow: 0 0 0 99999px rgba(0,0,0,.6);
    @apply p-4;
    @apply mt-4;
    @apply mb-16;
}

#app .v-tour__target--highlighted.v-tour-no-indents {
    padding: 0;
    margin: 0;
}

.overflow-toggle-tour {
  @apply overflow-hidden;
}

body.v-tour--active .overflow-toggle-tour {
  @apply overflow-visible;
}

.overflow-toggle-tour-scroll {
    @apply overflow-x-hidden;
    @apply overflow-y-scroll;
}

body.v-tour--active .overflow-toggle-tour-scroll {
    @apply overflow-visible;
}
</style>
