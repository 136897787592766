import { TourName } from '~/typings/enums';

export default {
    name: TourName.TherapyOverview,
    data(): any {
        return {
            steps: [
                {
                    target: '[data-v-step="therapy-overview:filters"]',
                    params: {
                        placement: 'bottom',
                    },
                    content: `
                        <div class="flex-column mb-4 text-left">
                            <h5 class="font-medium font-condensed mb-2">
                                Overzichten
                            </h5>
                            <p>
                                Bovenin kunt u in het zoekveld zoeken op patiënt, BSN
                                of geboortedatum om eenvoudig uw patiënt te vinden.
                            </p>
                        </div>
                    `,
                },
                {
                    target: '[data-v-step="therapy-overview:detail-button"]',
                    params: {
                        placement: 'left',
                    },
                    content: `
                        <div class="flex-column mb-4 text-left">
                            <h5 class="font-medium font-condensed mb-2">
                                Acties
                            </h5>
                            <p>
                                Indien mogelijk, klik hier op "Bekijk" of "Bewerk" en krijg alle gegevens over
                                de betreffende patiënt.
                            </p>
                        </div>
                    `,
                },
            ],
        };
    },
};
